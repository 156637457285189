import React from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import { graphql } from "gatsby";
import "../../global.scss";

const PCIThankyou = () => {
    return (
        <div style={{padding: "100px 0px"}}>
            <Container>
                <Row>
                    <Col>
                        <div style={{ textAlign: "center", paddingTop: "80px" }}>
                            <Title>
                                <Trans>Your Payment is Complete!</Trans>
                            </Title>
                            <PaddedImage width={"100px"} src="/images/checkout_Success.png" alt={"successful payment"} />
                            <p>
                                <Trans>Thank you for choosing PhoneBox.</Trans>
                            </p>
                            <p>
                                <Trans>We have sent the payment receipt to your registered email address. Please check your email for more details.</Trans>
                            </p>
                            <p style={{ color: "grey" }}>
                                <Trans
                                    defaults="This page is no longer usable. If you wish to process another payment under the same or a different account, please log into your online account or simply contact our customer support in order to receive a <strong>new payment link.</strong>"
                                    components={{ 
                                        strong: <strong/>
                                    }}
                                />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PCIThankyou;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PaddedImage = styled(Image)`
    padding-top:20px;
    padding-bottom:30px;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    height: 100%;
    line-height: 40px;
    color: #0494ca;
`;